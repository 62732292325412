<script setup lang="ts">
defineComponent({
  name: 'DefaultTermsLayout'
})
</script>

<template>
  <v-app theme="dreissigelf">
    <Header />
    <v-main class="pa-0 ma-0">
      <v-container fluid class="page-wrapper pa-0 ma-0">
        <div class="maxWidth">
          <NuxtPage />
        </div>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>
